import Main from "./Components/Main";


function App() {
  return (
       <Main/> 
  );
}

export default App;
