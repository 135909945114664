// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    background-color: aliceblue;
}
.container{
    margin-top: 0%;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.payment-name-container,.payment-amount-container{
    display: block;
    direction: rtl;
}
.payment-name-label,.payment-amount-label{
    margin:2%;
    width:70%;
    display: inline-block;
}

.payment-name-select,.payment-amount-input{
    text-align: center;
    width:30%;
    border-radius: 8px;
}
.payment-method{
    margin:5%;
}

@media only screen and (max-width: 600px) {
    .payment-method{
        margin:15%;
    }

    .payment-name-select,.payment-amount-input{
        width:30%;
    }

    .payment-name-label,.payment-amount-label{
        margin:2%;
        width:70%;
    }

    .payment-name-container,.payment-amount-container{
        margin-top: 10%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/style/main.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;AACA;IACI,cAAc;IACd,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,cAAc;AAClB;AACA;IACI,SAAS;IACT,SAAS;IACT,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,SAAS;AACb;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,SAAS;IACb;;IAEA;QACI,SAAS;QACT,SAAS;IACb;;IAEA;QACI,eAAe;IACnB;EACF","sourcesContent":["body{\n    background-color: aliceblue;\n}\n.container{\n    margin-top: 0%;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n}\n.payment-name-container,.payment-amount-container{\n    display: block;\n    direction: rtl;\n}\n.payment-name-label,.payment-amount-label{\n    margin:2%;\n    width:70%;\n    display: inline-block;\n}\n\n.payment-name-select,.payment-amount-input{\n    text-align: center;\n    width:30%;\n    border-radius: 8px;\n}\n.payment-method{\n    margin:5%;\n}\n\n@media only screen and (max-width: 600px) {\n    .payment-method{\n        margin:15%;\n    }\n\n    .payment-name-select,.payment-amount-input{\n        width:30%;\n    }\n\n    .payment-name-label,.payment-amount-label{\n        margin:2%;\n        width:70%;\n    }\n\n    .payment-name-container,.payment-amount-container{\n        margin-top: 10%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
